@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
}

img,
video {
  max-width: none;
  height: auto;
}