.customToggleWrapper {
  .switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 10px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E6E6E6;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 0px;
    bottom: -4px;
    background-color: white;
    // border-radius: 4px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border: 1px solid black;
    background-image: url("../assets/images/toggle-icon.svg");
  }

  /* Active state styles for different filter types */
  input:checked+.greenFilter {
    background-color: #377e2280;

    /* Green background for vegetarian */
    &:before {
      background-color: #377E22;
      border-color: #377E22;
      background-image: url("../assets/images/veg-food-type.svg");
    }
  }

  input:checked+.redFilter {
    background-color: #d2000088;

    /* Red background for non-vegetarian */
    &:before {
      background-color: #d2000088;
      border-color: #d2000088;
      background-image: url("../assets/images/non-veg-food-type.svg");
    }
  }

  input:checked+.yellowFilter {
    background-color: #de8d007e;

    /* Yellow background for eggetarian */
    &:before {
      background-color: #de8d007e;
      border-color: #de8d007e;
      background-image: url("../assets/images/egg-food-type.svg");
    }
  }

  /* Default active state (removed since we have specific styles for each type) */
  input:checked+.slider {
    &:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }

  input:checked+.drinksFilter {
    background-color: #696969;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 10px;
    height: 12px;
    width: 35px;
  }
}

.activeVeg {
  color: #377E22;
}

.activeNonVeg {
  color: #D20000;
}

.activeEgg {
  color: #DE8D00;
}

.ant-modal-content {
  padding: 0 !important;
  border-radius: 1.5rem !important;
  background-color: transparent !important;
}

// To Hide Scroll Bar
.hide-scrollbar::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar in WebKit browsers */
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes moveBackAndForth {
  from {
    left: 0;
  }

  to {
    left: calc(100% - 16px);
  }
}

.repeatCustomizationModal {
  position: fixed !important;
  inset: 0 !important;
  z-index: 1000 !important;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}